import { atom } from "recoil";

export interface Action {
    id?: number;
    createdUser: number;
    displayName: string;
    enabled: boolean;
    name: string;
    createdDate: number;
    serviceCode: string;
    updatedDate: number;
    updatedUser: number;
    url: string;
}

export const Actions = atom<Action[]>({
    key: 'actions',
    default: [],
});

export const PermissionsList = atom<any[]>({
    key: 'permissionsList',
    default: [],
});