
const APIS: any = {
  USER: {
    LOGIN: "/login",
    GET_ALL_USERS: "/user/getAllUser",
    ADD_UPDATE_USER: "/user/createOrUpdate",
  },
  ROLE: {
    GET_ALL_ROLES: "/user/getAllRoles",
    ADD_UPDATE_ROLE: "/user/add/role",
    USERS_ROLE_MAPPING: "/dashboard/postUserRoleMapping",
  },
  DATA_SOURCE: {
    GET_ALL_DATA_SOURCE: "/dashboard/getAllDataSources",
    ADD_UPDATE_DATA_SOURCE: "/dashboard/postDataSource",
    GET_DATA_SOURCE: "/dashboard/getDataSource"
  },
  VISUALIZATION: {
    GET_ALL_VISUALIZATION: "/dashboard/getAllVisualizations",
    ADD_UPDATE_VISUALIZATION: "/dashboard/postChart",
    GET_VISUALIZATION: "/dashboard/getChartConfig?visualizationCode="
  },
  DASHBOARD: {
    GET_ALL_DASHBOARD: "/dashboard/getAllDashboards",
    ADD_UPDATE_DASHBOARD: "/dashboard/postDashboard",
    GET_DASHBOARD: "/dashboard/getDashboard?profileName="
  },
  DATALAKE: {
    GET_ALL_DATALAKE: "/dashboard/getAllDataLakes",
    ADD_UPDATE_DATALAKE: "/dashboard/postDataLake",
    GET_DATALAKE: "/dashboard/getDataLake?id=",
    PIN_ITEM: "/dashboard/pinItem"
  },
  ACTIONS: {
    GET_ALL_ACTIONS: "/dashboard/getAllActions",
    GET_ACTIONS_FOR_ROLE: "/dashboard/getActionsForRole?roleId="
  },
  DATA_WAREHOUSE: {
    GET_ALL_DATA_WAREHOUSE: "/dashboard/getAllDataWarehouses",
    ADD_UPDATE_DATA_WAREHOUSE: "/dashboard/postDataWarehouse",
    GET_DATA_WAREHOUSE: "/dashboard/getDataWarehouse"
  }
};

export { APIS };