import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "states";
import { RouteConstants } from "./constants";

// Code spiltting
const Home = React.lazy(() =>
  import("./components/pages/index").then(({ Home }) => ({
    default: Home,
  }))
);

const DataSourceHome = React.lazy(() =>
  import("./components/pages/index").then(({ DataSourceHome }) => ({
    default: DataSourceHome,
  }))
);

const VisualizationHome = React.lazy(() =>
  import("./components/pages/index").then(({ VisualizationHome }) => ({
    default: VisualizationHome,
  }))
);

const Login = React.lazy(() =>
  import("./components/pages/index").then(({ Login }) => ({
    default: Login,
  }))
);

const UsersHome = React.lazy(() =>
  import("./components/pages/index").then(({ UsersHome }) => ({
    default: UsersHome,
  }))
);

const RolesHome = React.lazy(() =>
  import("./components/pages/index").then(({ RolesHome }) => ({
    default: RolesHome,
  }))
);

const DataLakeHome = React.lazy(() =>
  import("./components/pages/index").then(({ DataLakeHome }) => ({
    default: DataLakeHome,
  })))

const DataWarehouseHome = React.lazy(() =>
  import("./components/pages/index").then(({ DataWarehouseHome }) => ({
    default: DataWarehouseHome,
  }))
);

const Router = () => {
  const auth = useRecoilValue(authState);
  return (
    <Suspense fallback={<div className=""></div>}>
      <BrowserRouter>
        <Routes>
          <Route path={RouteConstants.login} element={<Login />} />
          <Route path={RouteConstants.root} element={<Login />} />

          <Route element={<ProtectedRoute auth={auth} />}>
            <Route path={RouteConstants.dashboards} element={<Home />} />
            <Route
              path={RouteConstants.visualizations}
              element={<VisualizationHome />}
            />
            <Route
              path={RouteConstants.datasources}
              element={<DataSourceHome />}
            />
            <Route path={RouteConstants.users} element={<UsersHome />} />
            <Route path={RouteConstants.roles} element={<RolesHome />} />
            <Route path={RouteConstants.datalake} element={<DataLakeHome />} />
            <Route path={RouteConstants.datawarehouses} element={<DataWarehouseHome />} />
          </Route >
        </Routes >
      </BrowserRouter >
    </Suspense >
  );
};

interface ProtectedRouteProps {
  auth: any;
  redirectPath?: string;
  children?: any;
}

const ProtectedRoute = ({
  auth,
  redirectPath = RouteConstants.root,
  children,
}: ProtectedRouteProps) => {


  if (!Object.keys(auth).length) {
    return <Navigate to={redirectPath} />;
  }

  return children ? children : <Outlet />;
};

export default Router;
