import { atom } from "recoil";
import { Role } from "states";

interface DBInfo {
    title: string;
    description?: string;
}

interface DBAccess {
    accessConfig: any;
    escapedAccessConfig: Role[];
}

interface DBLayout {
    layoutConfig: string;
}

interface DBFilter  {
    visualConfig: string,
    showDateFilter: boolean,
}

interface DBConfig {
    dashboardConfig: string;
}

interface DBWidgetsConfig {
    widgetsConfig: string;
}


interface DashboardData {
    info: DBInfo,
    layout: DBLayout,
    filters: DBFilter,
    config: DBConfig,
    widgets: DBWidgetsConfig,
    access: DBAccess
}

// export interface DashboardPayload  {
//     id?: number;
//     dashboardId: string; 
//     title: string;
//     isPinned: boolean;
//     data: DashboardData[];
//     type: string;
// } 

export interface Dashboard {
    profile: string;
    id?: number;
    dashboardId: string;
    title: string;
    isPinned: boolean;
    type: string;
    data: DashboardData[];
    timestamp?: any;
}

export const dashboardsState = atom<Dashboard[]>({
    key: 'dashboards',
    default: [],
  });

// Current Dashboard
export const currentDashboardState = atom<Dashboard>({
    key: "currentDashboard",
    default: {} as Dashboard,
  });

  export const updateDashboardItemState = atom<Dashboard[]>({
    key: "updateDashboardItem",
    default: [],
});