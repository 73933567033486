import { atom } from "recoil";


interface DSInfo {
    title: string;
    description?: string;
    subTitle?: string;
}
interface DSConnection {
    url: string;
    port?: string;
    username?: string;
    password?: string;
}

interface DataSourceData {
    info: DSInfo,
    connection: DSConnection
}

export interface DataSource {
    id?: number;
    title: string;
    timestamp?: number;
    srcType: string;
    isPinned: boolean;
    type: string;
    data: DataSourceData[]
}

export const dataSourcesState = atom<DataSource[]>({
    key: 'dataSources',
    default: [],
});


// Current DataSource
export const currentDataSourceState = atom<DataSource>({
    key: "currentDataSource1",
    default: {} as DataSource,
});
