const RouteConstants: any = {
  root: "/",
  login: "/login",
  dashboards: "/dashboards",
  visualizations: "/visualizations",
  datasources: "/data-sources",
  datawarehouses: "/data-warehouses",
  users: "/users",
  roles: "/roles",
  datalake: "/datalake"
};

export { RouteConstants };
