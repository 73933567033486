import { atom } from "recoil";

export interface User {
    id?: number;
    avatarUrl?: any;
    profileColor?: any;
    firstName?: string;
    emailId: string;
    phoneNo?: string;
    username?: string;
    roles?: any;
}

export const usersForCurrentRoleState = atom<User[]>({
    key: "usersForCurrentRole",
    default: [],
});

export const usersState = atom<User[]>({
    key: 'users',
    default: [],
  });

// Current user
export const currentUserState = atom({
    key: "currentUser",
    default: {} as User,
  });

