import { atom } from "recoil";

export interface DataWarehouse {
    id?: number;
    title: string;
    timestamp?: number;
    datasourceId: number,
    datasourceName: string,
    isPinned: boolean,
    indexName: string,
    type: string
}

export const dataWarehouseState = atom<DataWarehouse[]>({
    key: "dataWarehouse",
    default: [],
});

// Current Datawarehouse
export const currentDataWarehouseState = atom<DataWarehouse>({
    key: "currentDataWarehouse",
    default: {} as DataWarehouse,
});

export const currentDataState = atom<object[]>({
    key: "currentData",
    default: [],
});

export const updateItemState = atom<DataWarehouse[]>({
    key: "updateItem",
    default: [],
});