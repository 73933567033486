import { atom } from "recoil";
import { Role } from "./RolesState";


interface VisualizationInfo {
    title: string;
    subTitle?: string;
    chartType: string;
    description?: string;
}
interface  VisualizationConfig {
    config: string;
}

interface AccessInfo {
    accessConfig: string;
    escapedAccessConfig: Role[];
}

interface VisualizationData {
    info: VisualizationInfo,
    data: {
        query: string;
    }
    additionalConfig: VisualizationConfig;
    access: AccessInfo
    dashboards?: any,
}


export interface Visualization {
    id?: number;
    timestamp?: number;
    title: string;
    data: VisualizationData[],
    isPinned: boolean;
    isDecimal: boolean;
    type: string;
    visualizationCode: string;
    visualizationPermission: any[]
}

export const visualizationsState = atom<Visualization[]>({
    key: 'visualizations',
    default: [],
  });

// Current Visualisation
export const currentVisualizationState = atom<Visualization>({
    key: "currentVisualisation",
    default: {} as Visualization,
  });

  export const updateVisualizationItemState = atom<Visualization[]>({
    key: "updateVisualizationItem",
    default: [],
});
