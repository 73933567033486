const DATASOURCE_TYPE = {
  DRUID: "Druid",
  ES: "ES",
  REDIS: "Redis",
  MYSQL: "MySql",
  POSTGRESQL: "postgreSql"
}
//[ bar , pie , stackedbar , line , horizontalBar , doughnut , heat , radar ]

const CHART_TYPE = {
  BAR: "bar",
  PIE: "pie",
  STACKBAR: "stackedbar",
  LINE: "line",
  HORIZONTALBAR: "horizontalBar",
  DOUGHNUT: "doughnut",
  HEAT: "heat",
  RADAR: "radar",
  SCATTER: "scatter",
  BUBBLE: "bubble",
  TABLE: "table",
  TABLEBAR: "tableBar",
  TABVIEW: "tabView",
  METRIC: "metric",
}

const ENTITY_TYPE = {
  DASHBOARD: "dashboard",
  VISUALIZATION: "visualization",
  DATASOURCE: "data_source",
  USER: "user",
  ROLE: "role",
  DATALAKE: "datalake",
  DATAWAREHOUSE: "data_warehouse"
}

const MOCK_CHART_DATA = [
  {
    "id": 0,
    "vizType": "bar",
    "headerName": "Show Legend",
    "color": "",
    "plots": [
      {
        "id": 0,
        "value": "Jan"
      },
      {
        "id": 1,
        "value": "Feb"
      },
      {
        "id": 2,
        "value": "Mar"
      }
    ]
  },
  {
    "id": 1,
    "vizType": "bar",
    "headerName": "Region 1",
    "color": "#00C6A4",
    "plots": [
      {
        "id": 0,
        "value": 175
      },
      {
        "id": 1,
        "value": 100
      },
      {
        "id": 2,
        "value": 120
      }
    ]
  }
];

export { DATASOURCE_TYPE, CHART_TYPE, ENTITY_TYPE, MOCK_CHART_DATA };