import { atom } from "recoil";

// Current menu
export const currentMenu = atom({
  key: "currentMenu",
  default: "",
});

export const newItemState = atom<boolean>({
  key: "newItem",
  default: false,
});

export const showUsersRoleModalState = atom<boolean>({
  key: "usersRoleModal",
  default: false,
});

export const editItemState = atom<boolean>({
  key: "editItem",
  default: false,
});

export const userTabSelectedState = atom<boolean>({
  key: "userTabSelected",
  default: false,
});

export const VisualFilteredSearchList = atom<any[]>({
  key: 'visualisationFilteredList',
  default: [],
});