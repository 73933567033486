import { atom } from 'recoil';

export interface Role {
  id?: number;
  name?: string;
}

export interface removeDashboards {
  dashboards: string[];
  visualizationCode: any;
}

export interface insertDashboards {
  dashboards: string[];
  visualizationCode: any;
}

export const rolesState = atom<Role[]>({
  key: 'roles',
  default: [],
});

// Current role
export const currentRoleState = atom({
  key: "currentRole",
  default: {} as Role,
});

export const visualisationRolesState = atom<Role[]>({
  key: 'visualisationRoles',
  default: [],
});

export const visualisationDashboardsState = atom<any[]>({
  key: "visualisationDashboards",
  default: [],
});

export const dashboardRolesState = atom<Role[]>({
  key: 'dashboardRoles',
  default: [] ,
});

export const deleteVisualisationDashboardsState = atom<removeDashboards>({
  key: "deleteVisualisationDashboards",
  default: {} as removeDashboards,
});

export const insertVisualisationDashboardsState = atom<insertDashboards>({
  key: "insertVisualisationDashboards",
  default: {} as insertDashboards,
});

export const initialVisualisationDashboardsState = atom<any[]>({
  key: "initialVisualisationDashboards",
  default: [],
});

