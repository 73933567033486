import { atom } from "recoil";

export interface DataLake {
    id?: number;
    title: string;
    timestamp?: number;
    dataSourceId: number,
    dataSourceName: string,
    isPinned: boolean,
    indexName: string,
    type: string
}

export const dataLakeState = atom<DataLake[]>({
    key: 'dataLake',
    default: [],
});

export const currentDataLakeState = atom<DataLake>({
    key: "currentDataLake",
    default: {} as DataLake,
});

export const currentDataLakeData = atom<object[]>({
    key: "data",
    default: [],
});

export const filteredSearchList = atom<any[]>({
    key: 'filteredList',
    default: [],
});